@import '@/styles/styles';

.container {
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;

    @include media-breakpoint-down(md) {
        margin-top: -36px;
    }
}

.headText {
    color: #ffffff;
    font-family: 'Questrial';
    font-size: 40px;
    font-weight: 400;
    letter-spacing: -0.03em;
    line-height: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: 630px;
    text-align: center;
}
.subText {
    color: #ffffff;
    font-family: 'Questrial';
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    margin: 39px 0;
    text-align: center;

    @include media-breakpoint-down(md) {
        font-family: 'DM Sans';
        font-size: 18px;
        line-height: 24px;
        margin: 28px 0 14px;
    }
}
