@import '@/styles/variables';
@import '@/styles/styles';

.body {
    width: 100%;
    height: 100%;
    position: relative;
}

.wrapper {
    display: grid;
    width: 100%;
    height: 100%;
    gap: 50px;
    position: relative;
    grid-template-columns: 50% auto 50%;

    @media screen and (max-width: 1300px) {
        grid-template-columns: 1fr auto 1fr;
        gap: 40px;
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
        gap: 30px;
    }
}

.wrapperText {
    width: 100vw;
    margin-left: calc(50% - 50vw);
}

.divider {
    width: 1px;
    height: 100%;
    border: 1px solid #fff;
    position: relative;

    @include media-breakpoint-down(lg) {
        width: 100%;
        margin-left: 0;
    }
}
