@import '@/styles/variables';
@import '@/styles/styles';

.container {
  position: relative;
  padding: 60px 0px 90px;

  @media only screen and (min-width: 1300px) {
    margin-left: -4.5rem;
    margin-right: -4.5rem;
  }

  @include media-breakpoint-down(lg) {
    padding: 10px 0px 65px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: calc(15px - (50vw - 50%));
    margin-right: calc(15px - (50vw - 50%));
    width: calc(100% - (30px - (100vw - 100%)));
  }

  .backgroundColor {
    position: absolute;
    width: 100vw;
    background-color: #00D7E1;
    left: 0;
    top: 0;
    height: 100%;
    margin-left: calc(50% - 50vw);
    z-index: 0;
  }


  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .wrapperTag {
    background-color: #1C1624;
    padding: 4px 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: fit-content;
    border-radius: 24px;
    margin-bottom: 10px;
    color: #fff;

    .tag {
      font-family: "DM Sans";
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  h2 {
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .title {
    font-family: 'Questrial';
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
    z-index: 1;
    position: relative;
    margin-bottom: 30px;

    @include media-breakpoint-down(lg) {
      font-size: 40px;
      font-weight: 400;
      line-height: 56px;
      margin-bottom: 20px;
    }
  }

  .description {
    font-family: "DM Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    z-index: 1;
    position: relative;

    @include media-breakpoint-down(lg) {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .wrapperCard {
    margin-left: calc(50% - 50vw);
    margin-top: 30px;
    overflow: hidden;
    position: relative;
    width: 100vw;

    h3 {
      padding: 0;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    @media screen and (max-width: 1320px) {
      padding-left: 10px;
    }

    .box {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      @include layout();
      padding: 0;

      @include media-breakpoint-down(lg) {
        padding-left: 0;
      }
    }

    :global {
      .swiper {
        overflow: visible;

        .swiper-wrapper {
          .swiper-slide {
            width: auto;
          }
        }
      }
    }

    .item {
      padding: 32px;
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 637px;
      border-radius: 24px;
      background-color: #fff;
      min-height: 350px;

      @include media-breakpoint-down(lg) {
        max-width: 300px;
        padding: 24px;
        min-height: 448px;
        height: 100%;
      }

      .icon {
        width: 64px;
        height: 64px;
        display: flex;
        align-self: center;
        justify-content: center;
        border-radius: 100%;
        margin-bottom: 13px;

        .wrapperImg {
          width: 100%;
          max-width: 54px;
          height: 54px;
        }

        >div>span>img {
          width: 100%;
          max-width: 54px;
          height: 54px;
          object-fit: contain;
        }
      }

      .titleCard {
        font-family: 'Questrial';
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        margin-bottom: 10px;
      }

      .descriptionCard {
        font-family: "DM Sans";
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
      }
    }
  }

  .button {
    padding: 16px 40px;
    text-align: center;
    font-family: 'Questrial';
    font-size: 24px;
    font-weight: 400;
    margin-top: 40px;
    line-height: 24px;
  }

  .backgroundClipped {
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    top: -95px;
    width: 100vw;
    z-index: 0;

    @include media-breakpoint-down(md) {
      top: -77px;
    }

    &Clipped {
      top: 10%;
    }
  }

  .backgroundBottom {
    bottom: -100px;
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    width: 100vw;
    z-index: 0;

    @include media-breakpoint-down(md) {
      bottom: -30px;
    }

    &Clipped {
      top: 50%;
    }
  }
}