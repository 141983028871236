@import '@/styles/variables';
@import '@/styles/styles';

.container {
  position: relative;
  padding: 64px 0px 120px;

  @media only screen and (min-width: 1300px) {
    margin-left: -4.5rem;
    margin-right: -4.5rem;
  }

  @include media-breakpoint-down(lg) {
    padding: 50px 0px 120px;
    margin-top: 40px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 32px;
    padding: 50px 0px 120px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: calc(15px - (50vw - 50%));
    margin-right: calc(15px - (50vw - 50%));
    width: calc(100% - (30px - (100vw - 100%)));
  }

  .backgroundColor {
    width: 100vw;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    background-color: #1C1624;
    margin-left: calc(50% - 50vw);
  }

  .wrapperBox {
    display: grid;
    grid-template-columns: 520px 1fr;
    gap: 20px;

    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h1,
    h2,
    h3,
    h4 {
      padding: 0;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-block-start: 0 !important;
      margin-block-end: 0 !important;
    }

    @include media-breakpoint-down(xl) {
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
      gap: 22px;
    }
  }

  .boxLeft {
    position: relative;


    .wrapperTag {
      background-color: #313133;
      border-radius: 100px;
      padding: 4px 16px;
      display: flex;
      align-content: center;
      justify-content: center;
      max-width: fit-content;
      margin-bottom: 20px;

      @include media-breakpoint-down(lg) {
        margin-bottom: 10px;
      }

      .tag {
        font-family: "DM Sans";
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #fff;
      }
    }

    .title {
      font-family: "Questrial";
      font-size: 48px;
      font-weight: 400;
      line-height: 56px;
      margin-bottom: 20px;
      max-width: 420px;

      @include media-breakpoint-down(lg) {
        font-size: 40px;
        line-height: 48px;
      }
    }

    .description {
      font-family: "DM Sans";
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 20px;
      color: #fff;

      @include media-breakpoint-down(lg) {
        margin-bottom: 0px;
      }
    }

    .button {
      padding: 16px 24px;
      background-color: #00D7E1;
      box-shadow: 0px 5px 10px 0px rgba(0, 215, 225, 0.49);
      background: #00D7E1;
      color: #000;
      text-align: center;
      font-family: 'Questrial';
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      width: fit-content;
      height: auto;
      align-items: center;
      justify-content: center;

      svg {
        display: none;
      }


      @include media-breakpoint-down(lg) {
        display: flex;
        margin: 24px auto 0px;
        align-items: center;
        justify-content: center;
        padding: 16px 24px;
        font-size: 24px;
        display: none;
      }

      @include media-breakpoint-down(sm) {
        display: flex;
        margin: 24px auto 0px;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 16px 24px;
        display: none;
        font-size: 24px;
      }
    }
  }

  .boxRight {
    position: relative;

    .boxCard {
      padding: 32px;
      background-color: #7C39FF;
      border-radius: 24px;
      position: relative;
      width: 100%;
      height: 100%;
      margin-bottom: 15px;

      @include media-breakpoint-down(lg) {
        padding: 24px;
      }

      .wrapperCardContent {
        display: flex;
        flex-direction: row;
        gap: 14px;

        @include media-breakpoint-down(lg) {
          flex-direction: column;
          gap: 7px;
        }

        .cardNumber {
          font-family: 'Questrial';
          font-size: 48px;
          font-weight: 400;
          line-height: 56px;

          @include media-breakpoint-down(lg) {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
          }
        }

        .cardText {
          position: relative;
          margin-bottom: 20px;

          .cardTitle {
            font-family: 'Questrial';
            font-size: 32px;
            font-weight: 400;
            line-height: 40px;
            max-width: 408px;
            margin-bottom: 20px;

            @include media-breakpoint-down(lg) {
              font-size: 24px;
              font-weight: 400;
              line-height: 32px;
              margin-bottom: 10px;
            }
          }

          .cardDescription {
            font-family: "DM Sans";
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            max-width: 618px;
          }


        }
      }
    }

    .cardDivider {
      width: 100%;
      height: 1px;
      opacity: 0.3;
      background: #FFF;
      margin-bottom: 10px;

      @include media-breakpoint-down(lg) {
        margin-bottom: 21px;
      }
    }

    .wrapperItem {
      position: relative;
      cursor: pointer;

      @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
      }

      .wrapperHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        .headerLeft {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
        }

        .icon {
          width: 40px;
          height: 40px;
          background-color: rgba(255, 0, 104, 1);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          flex-shrink: 0;

          .image {
            width: 100%;
            height: 100%;
            max-width: 21px;
            max-height: 21px;

            >span>img {
              width: 100%;
              height: 100%;
              object-fit: contain !important;
            }
          }
        }

        .text {
          font-family: "DM Sans";
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;

          @include media-breakpoint-down(sm) {
            max-width: 220px;
          }
        }
      }

      .wrapperDescription {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s all;
        display: none;

        .description {
          max-width: 676px;
          margin-bottom: 15px;

          @include media-breakpoint-down(lg) {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 20px;
            max-width: 100%;
          }
        }
      }

      &.expanded .wrapperDescription {
        display: block;
        max-height: 1000px;
        transition: max-height 0.5s all;
        transform: translateY(10px);

        @include media-breakpoint-down(lg) {
          transform: translateY(20px);
        }
      }

      .arrow {
        transition: transform 0.5s;

      }

      &.expanded .arrow {
        transition: transform 0.5s all;
        transform: translateY(-2.5px) rotate(180deg);
      }

      .dividerAccordion {
        width: 100%;
        height: 1px;
        background-color: #fff;
        margin-top: 10px;
        margin-bottom: 10px;
        opacity: 0.3;

        @include media-breakpoint-down(lg) {
          margin-top: 20px;
        }

      }
    }

    .wrapperItem:last-child {
      .dividerAccordion {
        display: none;
      }
    }

    .button {
      display: none;

      @include media-breakpoint-down(lg) {
        display: flex;
        margin: 24px auto 0px;
        align-items: center;
        justify-content: center;
        padding: 16px 24px;
        font-size: 24px;
        box-shadow: 0px 5px 10px 0px rgba(0, 215, 225, 0.49);
        background-color: #00D7E1 !important;
        background: #00D7E1;
        color: #000;
      }

      @include media-breakpoint-down(sm) {
        display: flex;
        margin: 24px auto 0px;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 16px 24px;
        font-size: 24px;
        box-shadow: 0px 5px 10px 0px rgba(0, 215, 225, 0.49);
        background-color: #00D7E1 !important;
        background: #00D7E1;
        color: #000;
      }
    }
  }

  .background {
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1;
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
      top: -52px;
    }

    @include media-breakpoint-down(sm) {
      display: block;
      top: -32px;
    }

    &Clipped {
      top: 50%;
    }
  }

  .backgroundBottom {
    bottom: 0;
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    width: 100vw;
    z-index: 0;
    display: none;

    &Clipped {
      top: 50%;
    }
  }
}