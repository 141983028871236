@import '@/styles/variables';
@import '@/styles/styles';

.container {
    // padding: 16px 0;

    // @include media-breakpoint-down(md) {
    //     padding: 5px 0;
    // }
}

.unactive {
    border: 2px solid lighten($mirage, 10%);
    transition: all 0.3s;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:hover) {
        width: 100%;
        background: transparent;
        border-color: $mirage;
        box-shadow: none;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
