@import '@/styles/styles';

.container {
    color: #1c1624;
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;

    p {
        margin: 0;
    }

    h1 {
        margin-block-start: 0;
        margin-block-end: 0;
    }


    .title {
        font-family: 'Questrial';
        font-size: 64px;
        font-weight: 400;
        line-height: 64px;
        margin-bottom: 17px;
    }

    .description {
        font-family: 'DM Sans';
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
    }

    @include media-breakpoint-down(md) {
        text-align: start;

        .title {
            font-size: 48px;
            line-height: 48px;
        }

        .description {
            font-size: 18px;
            line-height: 26px;
        }
    }
}