@import '@/styles/styles';

.container {
    margin-left: calc(50% - 50vw);
    margin-top: 50px;
    overflow: hidden;
    padding: 32px 32px 32px 0px;
    position: relative;
    width: 100vw;

    @media screen and (max-width: 1320px) {
        padding-left: 10px;
    }

    .box {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        @include layout();
        padding: 0;
    }

    :global {
        .swiper {
            overflow: visible;

            .swiper-wrapper {
                .swiper-slide {
                    width: auto;
                }
            }
        }
    }
}
