@import 'styles/styles';

.articleGrid {
    margin: 0 auto;
    max-width: 100%;
    transition: all 0.3s;

    transition-delay: 0.3s;
    width: 1440px;
}

.articlesContainer {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, calc(33% - 12px));
    -webkit-transition: all 1s;
    transition: all 1s;
}
.articleLarge {
    grid-column: span 2;
    width: 100%;
    display: flex;
}

.buttonLoadMore {
    align-items: center;
    background: linear-gradient(90deg, $secondary 0%, $barney 48.96%, $primary 100%);
    box-shadow: 0 5px 10px rgba($purplish-blue, 0.5);
    //border: 1px solid #e5e5e5;
    border-radius: 88px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    transition: background-color 0.3s ease-in-out;
    width: -moz-fit-content;
    width: fit-content;

    &:hover {
        background-color: black;
        span {
            color: $text-color-primary-light;
        }
        path {
            stroke: $text-color-primary-light;
        }
    }
}
.buttonLoadMoreContent {
    align-items: center;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    padding: 10px 32px;

    span {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $text-color-primary-light;
    }
}

.buttonLoadMore.loading {
    display: none;
}

.spinnerLoadMoreContainer {
    text-align: center;
    display: flex;
    justify-content: center;
}

.spinnerLoadMore {
    display: none;
    border-width: 3px !important;
    height: 32px;
    width: 32px;
    margin-left: 0;
    margin-right: 0;
}

.spinnerLoadMore.loading {
    display: block;
}
.loadMoreContainer {
    text-align: center;
    //height: 240px;
    margin-top: 64px;
    margin-bottom: 120px;
}

@media screen and (max-width: 1366px) {
    .articlesContainer {
        justify-content: flex-start;
        // grid-template-columns: repeat(2, auto);
        justify-items: flex-start;
    }
    .articleLarge {
        grid-column: span 2;
    }
}
@media screen and (max-width: 1200px) {
    .articlesContainer {
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 768px) {
    .loadMoreContainer {
        //height: 128px;
        margin-top: 0;
        padding-top: 24px;
        margin-bottom: 64px;
    }
}

@media screen and (max-width: 768px) {
    .articlesContainer {
        // grid-template-columns: repeat(auto-fill, minmax(345px, 424px));
        grid-template-columns: 1fr;
        justify-content: center;
        justify-items: center;
    }
    .articleGrid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .articleLarge {
        grid-column: auto;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        width: -moz-fit-content;
        width: fit-content;
    }
    .articleGrid {
        padding: 0;
    }
    .spinnerLoadMore,
    .buttonLoadMore {
        //margin: 0px auto 64px auto;
        max-width: 100%;
    }
    .buttonLoadMoreContent {
        max-width: 100%;
        //width: 345px;
    }
}
