@import '@/styles/variables';
@import '@/styles/styles';

.container {
    margin-bottom: 75px;
    padding-top: 172px;
    position: relative;

    @media only screen and (min-width: 1300px) {
        margin-left: -4.5rem;
        margin-right: -4.5rem;
    }

    @include media-breakpoint-down(md) {
        padding-top: 124px;
        margin-bottom: 40px;
    }
}

.background {
    background-color: $primary;
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 0;

    &Clipped {
        top: 50%;
    }
}
