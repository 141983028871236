@import '@/styles/variables';
@import '@/styles/styles';

.container {
  margin-bottom: 66px;
  padding-bottom: 20px;
  padding-top: 105px;
  position: relative;

  @media only screen and (min-width: 1300px) {
    margin-left: -4.5rem;
    margin-right: -4.5rem;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 32px;
    padding-bottom: 20px;
    padding-top: 104px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: calc(15px - (50vw - 50%));
    margin-right: calc(15px - (50vw - 50%));
    width: calc(100% - (30px - (100vw - 100%)));
  }

  .wrapperContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    width: 100%;
    height: auto;
    gap: 30px;

    .contentBoxLeft {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;

      p {
        margin-block-start: 0;
        margin-block-end: 0;
      }

      h1,
      h2,
      h3 {
        padding: 0;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .wrapperText {
        .subTitle {
          font-size: 24px;
          font-weight: 400;
          font-family: "DM Sans";
          line-height: 32px;
          color: #AAADB3;
          margin-bottom: 8px;
        }

        .title {
          font-size: 48px;
          font-weight: 400;
          font-family: 'Questrial';
          line-height: 56px;
          margin-bottom: 25px;
          color: #fff;
        }

        .description {
          font-size: 24px;
          font-weight: 400;
          font-family: "DM Sans";
          line-height: 32px;
          margin-bottom: 24px;
          color: #fff;
        }

        .button {
          padding: 16px 24px;
          font-size: 24px;
          text-align: center;
          font-family: 'Questrial';
          font-weight: 400;
          line-height: 24px;
          display: flex;
          width: fit-content;
          height: auto;
          align-items: center;
          justify-content: center;

          svg {
            display: none;
          }
        }
      }
    }

    .contentBoxRight {
      width: 100%;
      height: 100%;

      .img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 30px;
        box-shadow: 0px 24px 24px -8px rgba(75, 82, 101, 0.31);
        min-height: 568px;
      }
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;

      .contentBoxLeft {
        .wrapperText {
          .subTitle {
            margin-bottom: 17px;
          }

          .title {
            margin-bottom: 31px;
            font-size: 48px;
            line-height: 48px;
            letter-spacing: -1.44px;
          }

          .imageMobile {
            width: 100%;
            height: 267.292px;
            position: relative;
            border-radius: 30px;
            overflow: hidden;
            box-shadow: 0px 24px 24px -8px rgba(75, 82, 101, 0.31);
            margin-bottom: 48px;

            .imgMobile {
              width: 100%;
              height: 100%;
              box-shadow: 0px 24px 24px -8px rgba(75, 82, 101, 0.31);
            }
          }

          .description {
            margin-bottom: 35px;
          }
        }
      }

      .contentBoxRight {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      .contentBoxLeft {
        .wrapperText {
          .button {
            padding: 16px 25px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
          }
        }
      }

      .contentBoxRight {
        display: none;
      }
    }
  }
}

.background {
  background-color: #1C1624;
  height: 100%;
  left: 0;
  margin-left: calc(50% - 50vw);
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 0;
}

.backgroundClippedBottom {
  bottom: 0;
  top: unset;
  z-index: 1;
}