@import '@/styles/variables';
@import '@/styles/styles';

.body {
  width: 100%;
  height: 100%;
  position: relative;
}

.wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  gap: 80px;
  position: relative;
  grid-template-columns: 65% 1fr;


  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .increase {
    position: relative;
    padding-top: 40px;

    @include media-breakpoint-down(lg) {
      padding-top: 0;
    }

    .title {
      font-family: 'Questrial';
      font-size: 64px;
      font-weight: 400;
      line-height: 72px;
      color: #fff;
      margin-bottom: 15px;

      h2 {
        padding: 0;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      @include media-breakpoint-down(lg) {
        font-size: 40px;
        line-height: 48px;
        text-align: center;
      }
    }

    .description {
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      line-height: 32px;
      font-family: "DM Sans";

      @include media-breakpoint-down(lg) {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
      }
    }
  }

  .mail {
    button {
      float: right;
      padding: 12px 32px;
      height: 48px;
    }

    button>svg {
      display: none;
    }
  }
}

.wrapperText {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}