@import '@/styles/styles';
@import '@/styles/variables';

.gridGap {
    gap: 30px;

    @include media-breakpoint-down(lg) {
        gap: 15px;
    }
}

.container {
    height: 0;
    margin-top: 100px;
    padding-top: 48.6%;
    position: relative;
    width: 100%;

    @include media-breakpoint-down(md) {
        margin-top: 60px;
        padding-top: 200%;
    }
}

.content {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.wrapImg {
    background-color: rgba($color: $dark-grey, $alpha: 0.1);
    border-radius: $base-border-radius;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    transform: translateZ(0);

    @include media-breakpoint-down(lg) {
        border-radius: 24px;
    }
}

.col {
    display: grid;
    grid-template-columns: 42% 24.4% auto;
    height: 100%;
    width: 100%;

    @extend .gridGap;

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-template-rows: 36% 23% auto;
    }
}

.row {
    display: grid;

    @extend .gridGap;

    &--1 {
        grid-template-rows: 82% minmax(0, auto);
    }
    &--2 {
        grid-template-rows: repeat(2, 1fr);
    }
    &--3 {
        &--1 {
            grid-template-columns: 46% auto;
        }
        grid-template-rows: 30% minmax(0, auto);
    }

    @include media-breakpoint-down(md) {
        &--1 {
            grid-template-columns: auto 75px;
            grid-template-rows: 1fr;
        }
        &--2 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
        }
        &--3 {
            &--1 {
                grid-template-columns: 75px auto;

                > div:nth-child(1) {
                    order: 1;
                }
            }
            grid-template-rows: 1fr;
        }
    }
}

.maxHeight50 {
    max-height: 50%;
}

.squares {
    align-items: flex-end;
    display: flex;
    flex-shrink: 0;

    justify-content: flex-end;
    position: relative;
    width: 100%;

    @extend .gridGap;
}

.squareContainer {
    aspect-ratio: 1;
    border-radius: 37.5%;
    display: grid;
    flex-shrink: 0;
    grid-auto-columns: auto;
    height: 100%;
    max-height: 80px;
    max-width: 80px;

    @include media-breakpoint-down(lg) {
        border-radius: 24px;
    }

    @include media-breakpoint-down(md) {
        max-height: 75px;
        max-width: 75px;
        padding-top: min(100%, 75px);
    }

    &.fill {
        &Cyan {
            background-color: $color-cyan;
        }
        &AztecPurple {
            background-color: $aztec-purple;
        }
        &White {
            background-color: white;
        }
    }
}

.mdHide {
    display: grid;
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.mdShow {
    display: none;
    @include media-breakpoint-down(md) {
        display: grid;
    }
}
