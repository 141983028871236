@import '@/styles/styles';

.heroContainer {
    z-index: 0;
    height: auto;
    margin-left: calc(50% - 50vw);
    padding-bottom: 80px;
    padding-top: 180px;
    position: relative;
    width: 100vw;

    @include media-breakpoint-down(lg) {
        padding-bottom: 31px;
        padding-top: 154px;
    }
}

.pageContainer {
    height: 100%;
    margin: 0 auto;
    width: 100%;
    @include layout();

    &::after {
        background-color: rgba($color: $dark-coal, $alpha: 0.75);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}

.arrow {
    background-color: $darkest-grey;
    border-radius: 2px;
    height: 2px;
    margin-bottom: 161px;
    margin-left: auto;
    margin-top: 64px;
    position: relative;
    transition: all 0.3s ease;
    width: 50%;

    @include media-breakpoint-down(lg) {
        margin-bottom: 121px;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
    }

    &::before,
    &::after {
        background-color: $darkest-grey;
        border-radius: 2px;
        content: '';
        height: 2px;
        position: absolute;
        right: 1px;
        top: 0;
        transform-origin: center right;
        width: 10px;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

.divider {
    background-color: $light-grey;
    height: 1px;
    margin-bottom: 64px;
    width: 100%;
    position: relative;
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.lineSkeleton {
    @include skeleton-load();
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.slider {
    color: $white;
    display: flex;
    overflow: hidden;
    width: 100%;

    .item {
        position: relative;
        width: 364px;

        .dot {
            color: $darkest-grey;
        }

        @include media-breakpoint-down(lg) {
            width: 301px;
        }

        &:not(:last-child) {
            margin-right: 24px;
        }

        .info {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 16px;
        }
    }
}
