@import '@/styles/styles';

.container {
    &.loading {
        border-radius: $base-border-radius;
        overflow: hidden;
        transform: translateZ(0);
    }

    &.relative {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
    }
}
