.container {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;

    .loaderText {
        &:after {
            animation-duration: 3s;
            animation-iteration-count: infinite;
            animation-name: loading-text;
            content: 'Loading';
            font-weight: bold;
        }
    }
}

@keyframes loading-text {
    0% {
        content: 'Loading';
    }

    25% {
        content: 'Loading.';
    }

    50% {
        content: 'Loading..';
    }

    75% {
        content: 'Loading...';
    }
}
