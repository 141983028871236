@import 'styles/styles';
@import '@/styles/variables';

.container {
    height: 100%;
    margin: 100px 0;
    position: relative;
    width: 100%;

    &.spaceDefault {
        margin: 0;
    }
}

.methodOne {
    background-color: #7c39ff;
    border-radius: 30px;
    height: 331px;
    margin-bottom: 105px;
    padding: 40px;
    width: 1140px;

    &.spaceDefault {
        width: 100%;
        margin-bottom: 22px;

        @include media-breakpoint-down(lg) {
            margin-bottom: 16px;
        }
    }

    @include media-breakpoint-down(xl) {
        height: 100%;
        width: 100%;
    }

    .title {
        color: #ffffff;
        font-family: 'DM Sans';
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
    }

    .subTitle {
        color: #ffffff;
        font-family: 'DM Sans';
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 16px;
    }

    .groupRevPar {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr 1fr;

        @include media-breakpoint-down(lg) {
            grid-template-columns: 1fr;
            gap: 0;
        }

        .wrapper {
            margin: 39px 0 12px;
        }

        .text {
            color: #ffffff;
            font-family: 'DM Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        .wrapperInput {
            border: 1px solid #7c39ff;
            display: flex;
            margin: 20px 0 20px;
            position: relative;
            align-items: center;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }

        .prefix {
            left: 0;
            bottom: 0;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            margin-right: 5px;
            color: rgba(255, 255, 255, 0.5);
            // color: #ffffff;
        }

        .input {
            background-color: transparent;
            border: none;
            // color: rgba(255, 255, 255, 0.5);
            color: #ffffff;
            font-size: 18px;
            line-height: 24px;
            outline: none;
            transition: color 0.5s, border 0.5s;
            width: 100%;
            position: relative;

            &::placeholder {
                color: rgba(255, 255, 255, 0.5);
                transition: color 0.5s;
            }
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }
    }

    .totalRevPar {
        display: flex;

        .text {
            color: #ffffff;
            font-family: 'DM Sans';
            font-size: 32px;
            font-weight: 400;

            @include media-breakpoint-down(md) {
                font-size: 24px;
                font-weight: 400;
            }
        }

        .number {
            color: #ffffff;
            font-family: 'DM Sans';
            font-size: 32px;
            font-weight: 400;
            margin-left: 10px;

            @include media-breakpoint-down(md) {
                font-size: 24px;
                font-weight: 400;
            }
        }
    }
}
.methodTow {
    background-color: #7c39ff;
    background: #00d7e1;
    border-radius: 30px;
    height: 100%;
    margin-bottom: 30px;
    padding: 40px;
    width: 1140px;

    &.spaceDefault {
        width: 100%;
        margin-bottom: 0;
    }

    @include media-breakpoint-down(xl) {
        height: 100%;
        width: 100%;
    }

    .title {
        color: #000000;
        font-family: 'DM Sans';
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
    }

    .subTitle {
        color: #000000;
        font-family: 'DM Sans';
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 16px;
    }

    .groupRevPar {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr 1fr 1fr;

        @include media-breakpoint-down(lg) {
            grid-template-columns: 1fr;
            gap: 0;
        }

        .wrapper {
            margin: 39px 0 12px;
        }

        .text {
            color: #000000;
            font-family: 'DM Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        .wrapperInput {
            display: flex;
            margin: 20px 0 20px;
            position: relative;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }
        .prefix {
            left: 0;
            bottom: 0;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            margin-right: 5px;
        }
        .input {
            background-color: transparent;
            border: none;
            color: white;
            font-size: 18px;
            line-height: 24px;
            outline: none;
            transition: color 0.5s, border 0.5s;
            width: auto;
            position: relative;
            width: 100%;

            &::placeholder {
                color: rgba(255, 255, 255, 0.5);
                transition: color 0.5s;
            }
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }
    }

    .totalRevPar {
        display: flex;

        .text {
            color: #000000;
            font-family: 'DM Sans';
            font-size: 32px;
            font-weight: 400;

            @include media-breakpoint-down(md) {
                font-size: 24px;
                font-weight: 400;
            }
        }

        .number {
            color: #000000;
            font-family: 'DM Sans';
            font-size: 32px;
            font-weight: 400;
            margin-left: 10px;

            @include media-breakpoint-down(md) {
                font-size: 24px;
                font-weight: 400;
            }
        }
    }
}
