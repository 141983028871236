@import 'styles/styles';

.container {
    height: 100%;
    margin-bottom: 76px;
    margin-top: 75px;
    padding-bottom: 105px;
    position: relative;
    width: 100%;
    z-index: 0;

    @media only screen and (min-width: 1300px) {
        margin-left: -87px;
        margin-left: -87px;
        width: calc(100% + 174px);
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 38px;
        margin-top: 40px;
        padding-bottom: 61px;
    }

    @include media-breakpoint-down(sm) {
        margin-left: calc(15px - (50vw - 50%));
        margin-right: calc(15px - (50vw - 50%));
        width: calc(100% - (30px - (100vw - 100%)));
    }

    .background {
        background-color: #bff5f7;
        height: 100%;
        left: 0;
        margin-left: calc(50% - 50vw);
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: 0;

        &Clipped {
            background-color: #ffffff;
            left: 0;
            top: 0%;
        }
    }
}

.intro {
    height: 100%;
    margin-bottom: 130px;
    position: relative;
    width: 100%;
    z-index: 1;

    @include media-breakpoint-down(md) {
        margin-bottom: 77px;
    }

    .heading {
        font-family: $title-font-family;
        font-size: 64px;
        font-weight: 400;
        line-height: 64px;

        @include media-breakpoint-down(md) {
            font-size: 48px;
            font-weight: 400;
            line-height: 48px;
        }
    }

    .divider {
        background-color: $color-cyan;
        border-radius: 30px;
        height: 2px;
        margin: 15px 0 30px;
        width: 32px;

        @include media-breakpoint-down(md) {
            margin: 15px 0 17px;
        }
    }

    .title {
        font-family: 'Questrial';
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        max-width: 850px;

        @include media-breakpoint-down(md) {
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
        }
    }

    .description {
        color: $mid-grey;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 14px;
        max-width: 740px;

        @include media-breakpoint-down(md) {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }
}

.memberContainer {
    display: grid;
    gap: 41px 30px;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1320px;
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        gap: 6px;
    }

    .member {
        display: flex;
        height: 100%;
        width: 100%;

        @include media-breakpoint-down(md) {
            display: block;
            width: 100%;
        }

        .avatar {
            background-color: $blue;
            border-radius: 50%;
            display: flex;
            flex-shrink: 0;
            height: 120px;
            margin-right: 26px;
            overflow: hidden;
            position: relative;
            width: 120px;

            @include media-breakpoint-down(lg) {
                height: 80px;
                width: 80px;
            }
        }

        .infoBlock {
            height: 100%;
            margin-top: 10px;

            .name {
                color: #1c1624;
                font-size: 32px;
                font-weight: 400;
                line-height: 40px;
            }

            .jobTitle {
                color: #1c1624;
                font-size: 24px;
                font-weight: 400;
                line-height: 32px;
                margin: 10px 0 36px;
                opacity: 0.5;

                @include media-breakpoint-down(md) {
                    display: block;
                    margin: 9px 0 -2px;
                }
            }

            .description {
                align-self: stretch;
                color: #1c1624;
                flex-grow: 0;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                order: 1;

                @include media-breakpoint-down(lg) {
                    display: block;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.03em;
                }
            }
        }
    }
}
