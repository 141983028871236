@import 'styles/styles';

.container {
    display: grid;
    gap: 160px;
    grid-template-columns: 410px auto;
    height: 100%;
    margin: 75px auto;
    width: 100%;

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        gap: 0;
        margin: 40px auto;
    }

    @include media-breakpoint-down(sm) {
        margin-left: calc(15px - (50vw - 50%));
        margin-right: calc(15px - (50vw - 50%));
        width: calc(100% + (50vw - 50%));
    }
}

.titleContainer {
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;

    .title {
        font-family: $title-font-family;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        position: relative;

        > p {
            margin: 0;
        }

        @include media-breakpoint-down(md) {
            font-size: 28px;
            line-height: 34px;
        }
    }

    .titleDivider {
        background: $aztec-purple;
        border-radius: 40px;
        height: 2px;
        margin-top: 23px;
        opacity: 0.2;
        width: 32px;

        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
            margin-top: 10px;
        }
    }
}

.description {
    color: $mid-grey;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;

    > p {
        margin: 0;
    }

    @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 24px;
    }
}
