@import '@/styles/styles';

.container {
    width: 100%;
    height: 100%;

    @include media-breakpoint-down(lg) {
        max-width: 100%;
    }
}

.title {
    font-family: 'Questrial';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 64px;
    color: #ffff;

    @include media-breakpoint-down(md) {
        font-size: 40px;
        line-height: 40px;
    }

    @include media-breakpoint-down(sm) {
        text-align: center;
    }
}

.booking {
    display: flex;
    margin: 40px 0;
    width: 100%;

    @include media-breakpoint-down(lg) {
        padding: 0;
    }

    .icon {
        border-bottom: 1px solid #8c52ff;

        @include media-breakpoint-down(lg) {
            display: flex;
            align-items: center;
        }
    }

    .icon:last-child() {
        border-bottom: none;
    }

    .wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid #8c52ff;
        padding-left: 10px;
        padding-bottom: 10px;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            display: flex;
        }

        .text {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            color: #ffffff;
            width: 100%;

            @media screen and (max-width: 1200) {
                font-size: 18px;
                line-height: 24px;
            }

            @include media-breakpoint-down(lg) {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                width: 100%;
            }
        }

        .inputText {
            background-color: transparent;
            border: none;
            font-family: 'Questrial';
            color: #fff;
            text-align: right;
            font-size: 32px;
            line-height: 32px;
            outline: none;
            transition: color 0.5s, border 0.5s;
            width: 20%;
            position: relative;

            @media screen and (max-width: 1200) {
                font-size: 18px;
                line-height: 24px;
            }

            @include media-breakpoint-down(lg) {
                width: 100%;
                text-align: start;
                line-height: 40px;
            }

            &::placeholder {
                color: #ffffff;
                transition: color 0.5s;
            }
        }
    }
}

.extra {
    display: flex;
    margin: 30px 0;
    width: 100%;

    @include media-breakpoint-down(lg) {
        padding: 0;
    }

    .icon {
        @include media-breakpoint-down(lg) {
            display: flex;
            align-items: center;
        }
    }

    .icon:last-child() {
        border-bottom: none;
    }

    .wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-left: 10px;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            display: flex;
        }

        .text {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            color: #ffffff;
            width: 100%;

            @media screen and (max-width: 1200) {
                font-size: 18px;
                line-height: 24px;
            }

            @include media-breakpoint-down(lg) {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                width: 100%;
            }
        }

        .inputText {
            background-color: transparent;
            border: none;
            font-family: 'Questrial';
            color: #fff;
            text-align: right;
            font-size: 32px;
            line-height: 32px;
            outline: none;
            transition: color 0.5s, border 0.5s;
            width: 20%;
            position: relative;

            @include media-breakpoint-down(lg) {
                width: 100%;
                text-align: start;
                line-height: 40px;
            }

            &::placeholder {
                color: #ffffff;
                transition: color 0.5s;
            }
        }
    }
}

.description {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 33px;
}
