@import '@/styles/variables';
@import '@/styles/styles';

.container {
  margin-bottom: 66px;
  margin-top: 100px;
  padding: 0 0px 0;
  position: relative;

  @media only screen and (min-width: 1300px) {
    margin-left: -4.5rem;
    margin-right: -4.5rem;
  }

  @include media-breakpoint-down(lg) {
    padding-top: 0px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: calc(15px - (50vw - 50%));
    margin-right: calc(15px - (50vw - 50%));
    width: calc(100% - (30px - (100vw - 100%)));
  }

  .backgroundColor {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 90%;
    margin-left: calc(50% - 50vw);
    background: var(--RoomStay-Colours-Purple-Magenta, linear-gradient(45deg, #7C39FF 0%, #BC1DB5 49.12%, #FF0068 100%));
  }

  .wrapperContent {
    position: relative;
    z-index: 1;

    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  .wrapperAvatar {
    width: 80px;
    height: 80px;
    overflow: hidden;
    position: relative;
    border-radius: 100%;
    background-color: aqua;
    margin: 0 auto 20px;
  }

  .textMain {
    font-family: 'Questrial';
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    max-width: 1070px;
    margin: 0 auto 20px;

    @include media-breakpoint-down(lg) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .title {
    font-family: "DM Sans";
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    line-height: 24px;
    color: #fff;
    text-align: center;
    margin-bottom: 0;

    @include media-breakpoint-down(lg) {}
  }

  .description {
    font-family: "DM Sans";
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 50px;
    }
  }

  .wrapperImage {
    aspect-ratio: 1/1;
    height: auto;
    position: relative;
    width: 100%;

    @media screen and (max-width: 2000px) {
      aspect-ratio: 1/1;
      height: 100%;
      max-height: 760px;
    }

    @media screen and (max-width: 1920px) {
      aspect-ratio: 1/1;
      height: 100%;
      max-height: 720px;
    }

    @include media-breakpoint-down(lg) {
      aspect-ratio: 4/3;
      height: 100%;
    }

    @include media-breakpoint-down(md) {
      aspect-ratio: 1/1;
      height: 100%;
      height: 420px;
    }

    @include media-breakpoint-down(sm) {
      aspect-ratio: 1/1;
      height: 100%;
      height: 320px;
    }

    .image {
      height: 100%;
      left: 0;
      margin-left: calc(50% - 50vw);
      position: absolute;
      top: 0;
      width: 100vw;
    }
  }
}

.background {
  background: linear-gradient(305deg, rgb(124, 57, 255) -93.79%, rgb(188, 29, 181) 24.45%, rgb(255, 0, 104) 98.93%);
  height: 100%;
  left: 0;
  margin-left: calc(50% - 50vw);
  position: absolute;
  top: -166px;
  width: 100vw;
  z-index: 0;

  @include media-breakpoint-down(lg) {
    top: -100px;
    background: linear-gradient(305deg, rgb(124, 57, 255) -203.79%, rgb(188, 29, 181) 9.55%, rgb(255, 0, 104) 98.93%)
  }

  @include media-breakpoint-down(md) {
    top: -80px;
    background: linear-gradient(305deg, rgb(124, 57, 255) -203.79%, rgb(188, 29, 181) -49.55%, rgb(255, 0, 104) 112.93%)
  }
}

.backgroundBottom {
  bottom: -1px;
  top: unset;
  z-index: 2;
  height: 100%;
  background: linear-gradient(45deg, #7C39FF 0%, #BC1DB5 49.12%, #FF0068 100%);
  background: #fff;
  position: absolute;
  margin-left: calc(50% - 50vw);
  left: 0;
  width: 100vw;
}