@import 'styles/styles';

.root {
    padding-top: 40px;
    padding-bottom: 40px;
    font-family: $body-font-family;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 18px;
    line-height: 24px;
    position: relative;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        margin-left: calc(50% - 50vw);
        z-index: -1;
    }

    @include media-breakpoint-down(lg) {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    &.noPadding {
        padding-top: 0;
        padding-bottom: 0;
    }
    &.dark {
        color: $white;
    }
    h1 {
        margin: 0;
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
    }
    h2 {
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }
    p {
        margin: 0;
        font-size: 18px;
        line-height: 24px;
    }
    * + * {
        margin-top: 16px !important;
    }
}
