@import 'styles/styles';

.container {
    height: 100%;
    margin: 99px auto;
    width: 100%;
    max-width: 100%;
    @include alight-item-center;

    .containerWrapper {
        width: 1320px;
        height: 100%;
        padding: 0 1rem;

        @include media-breakpoint-down(xxl) {
            margin: 0 auto;
            max-width: 100%;
            padding: 0 1rem;
            width: 100%;
        }
    }

    &WrapperTitle {
        margin: 0 auto;

        &Text {
            font-size: 64px;
            font-weight: 400;
            line-height: 64px;

            > p {
                margin: 0;
            }

            @include media-breakpoint-down(sm) {
                font-size: 48px;
                line-height: 48px;
            }
        }

        &Divider {
            background: #00d7e1;
            border-radius: 40px;
            height: 2px;
            margin: 4px 0 24px;
            opacity: 0.2;
            width: 32px;
        }
    }

    &SubTitle {
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        margin-bottom: 6px;
        max-width: 850px;

        @include media-breakpoint-down(sm) {
            font-size: 24px;
            line-height: 32px;
        }

        > p {
            margin: 0;
        }
    }

    &Description {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        max-width: 740px;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
            line-height: 24px;
        }
        > p {
            margin: 0;
        }
    }
}
