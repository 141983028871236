@import '@/styles/styles';

.container {
    align-items: center;
    background-color: $color-cyan;
    border-radius: $base-border-radius;
    color: #1d1d1d;
    display: flex;
    flex-direction: row;
    gap: 47px;
    justify-content: space-between;
    padding: 80px 64px;
    width: 100%;

    .left {
        display: flex;
        flex-direction: column;
        max-width: 488px;

        .headline {
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            margin-bottom: 9px;
            letter-spacing: 4px;
            text-transform: uppercase;
        }

        .title {
            font-family: $title-font-family;
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 23px;
            letter-spacing: -0.03em;
        }

        .line {
            background: #1d1d1d;
            border-radius: 40px;
            height: 2px;
            margin-bottom: 12px;
            opacity: 0.2;
            width: 32px;
        }

        .description {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 6px;
        }
    }

    .right {
        position: relative;
        width: 50%;
        flex-shrink: 1;
        height: 229px;

        &-height {
            &-full {
                height: 400px;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        flex-direction: column;
        padding: 40px 24px;
        gap: 40px;

        .left {
            max-width: 100%;
            text-align: center;

            .headline {
            }

            .title {
                font-size: 24px;
                line-height: 32px;
            }

            .line {
                margin: 0 auto;
                margin-bottom: 8px;
            }

            .description {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 8px;
            }
        }

        .right {
            width: 100%;
            aspect-ratio: calc(224 / 69);
            max-height: 229px;
            height: auto;

            &-height {
                &-full {
                    max-height: 400px;
                    height: 300px;
                    margin-top: -30px;
                    margin-bottom: -30px;
                }
            }

            .image {
                position: initial;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .right {
            width: 100%;
            aspect-ratio: calc(200 / 69);
            max-height: 200px;
            height: auto;

            &-height {
                &-full {
                    height: 400px;
                }
            }

            .image {
                position: initial;
            }
        }
    }
}
