@import '@/styles/variables';
@import '@/styles/styles';

.container {
  position: relative;
  padding: 64px 0px 100px;

  @media only screen and (min-width: 1300px) {
    margin-left: -4.5rem;
    margin-right: -4.5rem;
  }

  @include media-breakpoint-down(lg) {
    padding: 0px 0px 64px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: calc(15px - (50vw - 50%));
    margin-right: calc(15px - (50vw - 50%));
    width: calc(100% - (30px - (100vw - 100%)));
  }


  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  h2 {
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .wrapperTag {
    background-color: #F3F3F5;
    padding: 4px 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: fit-content;
    border-radius: 24px;
    margin-bottom: 10px;

    .tag {
      font-family: "DM Sans";
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .title {
    font-family: 'Questrial';
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;

    @include media-breakpoint-down(lg) {
      font-size: 40px;
      font-weight: 400;
      line-height: 48px;
    }
  }

  .wrapperCard {
    margin-left: calc(50% - 50vw);
    margin-top: 30px;
    overflow: hidden;
    position: relative;
    width: 100vw;

    h3 {
      padding: 0;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    @media screen and (max-width: 1320px) {
      padding-left: 10px;
    }

    .box {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      @include layout();
      padding: 0;

      @include media-breakpoint-down(lg) {
        padding-left: 0;
      }
    }

    :global {
      .swiper {
        overflow: visible;

        .swiper-wrapper {
          .swiper-slide {
            width: auto;
            height: 100%;
          }
        }
      }
    }

    .item {
      padding: 32px;
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 420px;
      border-radius: 24px;
      min-height: 300px;
      background: var(--background-card, rgba(124, 57, 255, 0.10));

      @include media-breakpoint-down(lg) {
        max-width: 310px;
        padding: 24px;
        min-height: 350px;
        height: 100%;
      }

      .number {
        font-family: 'Questrial';
        font-size: 64px;
        font-weight: 400;
        line-height: 72px;
        margin-bottom: 15px;

        @include media-breakpoint-down(lg) {
          font-size: 48px;
          line-height: 56px;
        }
      }

      .number2 {
        font-family: 'Questrial';
        font-size: 64px;
        font-weight: 400;
        line-height: 72px;
        margin-bottom: 15px;

        @include media-breakpoint-down(lg) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .title {
        font-family: 'Questrial';
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        margin-bottom: 10px;
      }

      .subTitle {
        font-family: "DM Sans";
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 10px;

        @include media-breakpoint-down(lg) {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 7px;
        }
      }

      .description {
        font-family: "DM Sans";
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;

        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  .button {
    padding: 16px 24px;
    color: #fff;
    text-align: center;
    font-family: 'Questrial';
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 40px;
    display: flex;
    width: fit-content;
    height: auto;
    align-items: center;
    justify-content: center;

    svg {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
  }

  .backgroundClipped {
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    top: -85px;
    width: 100vw;
    z-index: 0;

    @include media-breakpoint-down(md) {
      top: -90px;
    }

    &Clipped {
      top: 10%;
    }
  }

  .backgroundBottom {
    bottom: 0;
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    width: 100vw;
    z-index: 1;
    display: none;

    &Clipped {
      top: 50%;
    }
  }
}