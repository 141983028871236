@import 'styles/styles';

.container {
    height: 100%;
    margin-bottom: 110px;
    margin-top: 76px;
    width: 100%;
    @include alight-item-center;

    @include media-breakpoint-down(md) {
        margin-bottom: 80px;
        margin-top: 40px;
    }

    @include media-breakpoint-down(sm) {
        margin-left: calc(15px - (50vw - 50%));
        margin-right: calc(15px - (50vw - 50%));
        width: calc(100% + (50vw - 50%));
    }

    .title {
        font-family: $title-font-family;
        font-size: 64px;
        font-weight: 400;
        line-height: 64px;
        margin-bottom: 79px;
        text-align: center;

        @include media-breakpoint-down(md) {
            // line-height: 40px;
            margin-bottom: 31px;
        }
    }

    .content {
        display: grid;
        gap: 66px 30px;
        grid-template-columns: repeat(3, 1fr);

        @include media-breakpoint-down(lg) {
            gap: 33px 30px;
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
        }

        .item {
            .icon {
                background: linear-gradient(45deg, #7c39ff 0%, #bc1db5 48.96%, #ff0068 100%);
                border-radius: $base-border-radius;
                height: 88px;
                margin: 0 auto;
                padding: 24px;
                width: 88px;
                position: relative;
                transform: translateZ(0);

                @include alight-item-center;

                @include media-breakpoint-down(md) {
                    border-radius: 22.5px;
                    height: 66px;
                    width: 66px;
                }
            }

            .text {
                font-family: 'Questrial';
                font-size: 28px;
                font-weight: 400;
                line-height: 36px;
                margin-top: 16px;
                text-align: center;

                @include media-breakpoint-down(md) {
                    font-size: 22px;
                    letter-spacing: -0.03em;
                    line-height: 28px;
                    width: 100%;
                }
            }
        }
    }
}
