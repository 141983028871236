@import '@/styles/variables';
@import '@/styles/styles';

.container {
    height: auto;
    left: 0;
    margin-left: calc(50% - 50vw);
    padding-left: 60px;
    padding-right: 60px;
    position: relative;
    width: 100vw;
    z-index: 1;

    @include media-breakpoint-down(lg) {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 125px;
    }

    .wrapperContainer {
        height: auto;
        margin: 0 auto;
        max-width: 1920px;
        position: relative;
        width: 100%;

        @include media-breakpoint-down(lg) {
            padding-bottom: 75px;
        }
    }

    .wrapperContent {
        align-items: center;
        display: grid;
        gap: 60px;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        margin: 0 auto;
        max-width: 1920px;
        padding-top: 105px;
        position: relative;
        z-index: 1;

        @include media-breakpoint-down(xxl) {
            gap: 40px;
            grid-template-columns: 620px auto;
            padding-top: 120px;
        }

        @include media-breakpoint-down(xl) {
            gap: 20px;
            grid-template-columns: 1fr 1fr;
        }

        @include media-breakpoint-down(lg) {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-top: 0;
        }

        .contentLeft {
            height: 720px;
            overflow: visible;
            position: relative;
            width: 100%;

            @include media-breakpoint-down(xl) {
                height: 420px;
            }

            .wrapperImage {
                height: 100%;
                left: 0;
                overflow: hidden;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;

                @media screen and (max-width: 1440px) {
                    left: -50px;
                    width: 100%;
                }

                img {
                    height: 100%;
                    overflow: hidden;
                    width: 100%;
                }

                @include media-breakpoint-down(lg) {
                    aspect-ratio: 4/3;
                    left: 0;
                    position: relative;
                }
            }

            @include media-breakpoint-down(lg) {
                height: auto;
            }
        }

        .contentText {
            float: right;
            max-width: 630px;
            position: relative;

            @include media-breakpoint-down(xl) {
                max-width: auto;
            }

            @include media-breakpoint-down(lg) {
                float: left;
                max-width: 100%;
            }

            p {
                margin-block-end: 0;
                margin-block-start: 0;
            }

            .subTitle {
                color: var(--room-stay-colours-coal, #1c1624);
                font-family: Questrial;
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: 40px;

                @include media-breakpoint-down(lg) {
                    font-size: 22px;
                    line-height: 28px;
                }
            }

            .title {
                color: #000;
                font-family: Questrial;
                font-size: 64px;
                font-style: normal;
                font-weight: 400;
                line-height: 72px;
                margin-top: 19px;

                @include media-breakpoint-down(lg) {
                    font-size: 28px;
                    line-height: 32px;
                    margin-top: 8px;
                }
            }

            .button {
                margin-top: 32px;

                @include media-breakpoint-down(lg) {
                    margin-top: 24px;
                }

                .content {
                    display: flex;
                    align-items: center;
                    gap: 18px;
                    padding: 10px 20px;

                    @include media-breakpoint-down(lg) {
                        gap: 10px;
                        padding: 5px 10px;
                    }

                    > span {
                        color: var(--Gray-Scale-Gray-0, #fff);
                        font-family: DM Sans;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 32px;
                        letter-spacing: -0.72px;

                        @include media-breakpoint-down(lg) {
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }

    .background {
        background-color: $primary;
        height: 100%;
        left: 0;
        margin-left: calc(50% - 50vw);
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: -1;

        &Clipped {
            top: 50%;
        }
    }
}
