@import '@/styles/variables';
@import '@/styles/styles';

.container {
    margin-bottom: 66px;
    padding-bottom: 114px;
    padding-top: 172px;
    position: relative;

    @media only screen and (min-width: 1300px) {
        margin-left: -4.5rem;
        margin-right: -4.5rem;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 32px;
        padding-bottom: 101px;
        padding-top: 124px;
    }

    .listButton {
        align-items: center;
        display: flex;
        gap: 10px;
        height: 100%;
        justify-content: center;
        margin-bottom: 120px;
        margin-top: 40px;
        position: relative;
        width: 100%;

        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            height: 100%;
            margin-bottom: 48px;
            margin-top: 10px;
            width: 100%;

            > div {
                width: 100%;
            }
        }
    }
}

.background {
    background-color: $color-cyan;
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 0;
}

.backgroundClippedBottom {
    bottom: 0;
    top: unset;
    z-index: 1;
}
