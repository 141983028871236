@import '@/styles/variables';
@import '@/styles/styles';

.container {
  aspect-ratio: 1/1;
  height: auto;
  position: relative;
  width: 100%;

  @media screen and (max-width: 2000px) {
    aspect-ratio: 1/1;
    height: 100%;
    max-height: 670px;
  }

  @include media-breakpoint-down(lg) {
    aspect-ratio: 4/3;
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    aspect-ratio: 1/1;
    height: 100%;
    height: 420px;
  }

  @include media-breakpoint-down(sm) {
    aspect-ratio: 1/1;
    height: 100%;
    height: 320px;
  }

  .wrapperImg {
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    top: 0;
    width: 100vw;
  }

  .background {
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    top: -20px;
    width: 100vw;
    z-index: 1;

    &Clipped {
      top: 50%;
    }
  }

  .backgroundBottom {
    bottom: 0;
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    width: 100vw;
    z-index: 1;

    &Clipped {
      top: 50%;
    }
  }
}