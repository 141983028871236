@import 'styles/styles';

.container {
    height: auto;
    left: 0;
    margin-left: calc(-50vw + 50%);
    padding: 0 60px;
    width: 100vw;
    height: 535px;

    @include media-breakpoint-down(lg) {
        height: 100%;
    }

    @include media-breakpoint-down(md) {
        padding: 0 15px;
    }

    .wrapperContent {
        height: auto;
        margin: 0 auto;
        max-width: 1320px;

        @include media-breakpoint-down(lg) {
            max-width: 100%;
        }

        .gridContent {
            background: var(--RoomStay-Colours-Coal, #1c1624);
            border-radius: 30px;
            box-shadow: 0px 15px 30px 0px rgba(124, 57, 255, 0.5);
            display: grid;
            gap: 30px;
            grid-template-columns: 1fr 1fr;
            height: 100%;
            padding: 83px;
            width: 100%;

            @include media-breakpoint-down(lg) {
                display: flex;
                flex-direction: column;
                padding: 20px;
            }

            .contentText {
                height: auto;
                max-width: 525px;
                width: 100%;

                @include media-breakpoint-down(lg) {
                    margin: 0 auto;
                    max-width: 100%;
                }

                @include media-breakpoint-down(sm) {
                    max-width: 305px;
                }

                p {
                    margin-block-end: 0;
                    margin-block-start: 0;
                }

                .subTitle {
                    color: var(--Gray-Scale-Gray-0, #fff);
                    font-family: Questrial;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: -0.96px;
                    line-height: 40px;

                    @include media-breakpoint-down(lg) {
                        font-size: 24px;
                        text-align: center;
                    }
                }

                .title {
                    color: var(--Gray-Scale-Gray-0, #fff);
                    font-family: Questrial;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: -1.44px;
                    line-height: 56px;
                    margin-top: 10px;

                    @include media-breakpoint-down(lg) {
                        font-size: 40px;
                        line-height: 48px;
                        margin-top: 0;
                        text-align: center;
                    }
                }

                .description {
                    color: var(--Gray-Scale-Gray-0, #fff);
                    font-family: DM Sans;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: -0.54px;
                    line-height: 24px;
                    margin-top: 30px;

                    @include media-breakpoint-down(lg) {
                        margin-top: 10px;
                        text-align: center;
                    }
                }
            }

            .contentForm {
                .beFormGroup {
                    background-color: red !important;
                }

                .helpdesk-form {
                    background-color: red !important;
                }

                .margBottom {
                    margin-bottom: 14px;

                    textarea {
                        border-bottom: 1px solid #8c52ff;
                    }
                }

                .button {
                    margin-top: 32px;

                    @media screen and (max-width: 324px) {
                        display: flex;
                        max-width: 100%;
                        flex-direction: column;
                        height: auto;
                        width: 100%;
                        position: relative;
                        padding: 0;
                    }
                }

                .description {
                    color: var(--RoomStay-Colours-Grey, #65606c);
                    font-family: DM Sans;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: -0.36px;
                    line-height: 16px;
                    margin-top: 15px;
                }
            }
        }
    }
}
