@import '@/styles/styles';

.container {
    position: relative;
    display: flex;
    flex-direction: row;
    color: $dark-coal;
    align-items: flex-start;
    justify-content: space-between;

    padding: 40px 0;

    @include media-breakpoint-down(lg) {
        padding: 16px 0;
    }
}

.background {
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    margin-left: calc(50% - 50vw);
}

.quoteMark {
    max-width: 73px;
    max-height: 67px;
    width: 100%;
    height: 100%;
    position: relative;
    margin-right: 32px;
    svg {
        width: 100%;
        height: 100%;
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.quoteContent {
    max-width: 946px;
    width: 100%;

    .quote {
        font-size: 40px;
        line-height: 48px;
        font-family: $title-font-family;

        p {
            margin: 0;
        }

        @include media-breakpoint-down(lg) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .author {
        margin-top: 24px;
        padding-left: 58px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 1em;
            width: 34px;
            height: 2px;
            border-radius: 2px;
            background-color: $aztec-purple;

            @include media-breakpoint-down(lg) {
                top: 0.7em;
            }
        }

        .name {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 8px;

            @include media-breakpoint-down(lg) {
                font-size: 18px;
                line-height: 24px;
            }
        }
        .jobTitle {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $text-color-secondary-dark;

            @include media-breakpoint-down(lg) {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}
