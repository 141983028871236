@import '@/styles/variables';
@import '@/styles/styles';

.container {
    height: auto;
    left: 0;
    margin-left: calc(50% - 50vw);
    overflow: hidden;
    padding: 0 60px;
    position: relative;
    width: 100vw;

    @include media-breakpoint-down(lg) {
        padding: 0 15px;
    }

    .wrapperContent {
        align-items: center;
        display: flex;
        height: 100%;
        margin: 0 auto;
        max-width: 1320px;
        padding: 148px 0 50px;
        width: 100%;

        @include media-breakpoint-down(lg) {
            padding: 138px 0;
        }

        .content {
            height: auto;
            width: 100%;

            p {
                margin-block-end: 0;
                margin-block-start: 0;
            }


            h1 {
                margin-block-end: 0;
                margin-block-start: 0;
            }

            .contentIntro {
                margin-bottom: 65px;

                .subTitle {
                    color: var(--RoomStay-Colours-Grey, #65606c);
                    font-family: Questrial;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: -0.96px;
                    line-height: 40px;
                    text-align: center;
                }

                .title {
                    color: var(--RoomStay-Colours-Coal, #1c1624);
                    font-family: Questrial;
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: -1.92px;
                    line-height: 72px;
                    margin-top: 16px;
                    text-align: center;

                    @include media-breakpoint-down(lg) {
                        font-size: 40px;
                        letter-spacing: -1.2px;
                        line-height: 48px;
                    }
                }

                .subDescription {
                    color: var(--RoomStay-Colours-Grey, #65606c);
                    font-family: Questrial;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: -0.96px;
                    line-height: 40px;
                    margin-top: 33px;
                    text-align: center;
                }

                .description {
                    color: var(--RoomStay-Colours-Purple, #7c39ff);
                    font-family: Questrial;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: -1.44px;
                    line-height: 56px;
                    margin-top: 17px;
                    text-align: center;

                    @include media-breakpoint-down(lg) {
                        font-size: 40px;
                        line-height: 48px;
                    }
                }
            }
        }
    }

    .background {
        height: 100%;
        left: 0;
        margin-left: calc(50% - 50vw);
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: -1;

        &Clipped {
            top: 50%;
        }
    }
}