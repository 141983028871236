@import '@/styles/styles';

.container {
    display: block;
    text-decoration: none;
    color: $white;
    width: 364px;
    position: relative;

    @include media-breakpoint-down(lg) {
        width: 301px;
    }
}

.info {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;

    .category {
        color: $color-cyan;
    }
}

.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    @include linesLimit(2);
}

.url {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}
