@import 'styles/styles';
@import '@/styles/variables';

.container {
    height: 100%;
    padding: 100px 0;
    width: 100%;

    @include media-breakpoint-down(lg) {
        margin-bottom: 28px;
        padding: 0;
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;

        &.left {
            flex-direction: row-reverse;

            @include media-breakpoint-down(lg) {
                flex-direction: column;
            }
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            gap: 30px;
            min-height: unset;
        }

        .wrapperImg {
            height: 100%;
            left: 0;
            margin-left: calc(50% - 50vw);
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 100vw;

            @include media-breakpoint-down(lg) {
                aspect-ratio: 4/3;
                height: 100%;
                margin-left: 0;
                max-height: 300px;
                position: relative;
                top: 0;
                width: 100%;

                &.hideImage {
                    display: none;
                }
            }

            .positionImg {
                height: auto;
                height: 100%;
                margin: 0 auto;
                max-width: 1440px;
                overflow: visible;
                position: relative;
                width: 100%;
            }

            .img {
                border-radius: 24px;
                height: 100%;
                max-width: 725px;
                position: absolute;
                right: 0;
                width: 100%;

                > span > img {
                    border-radius: 24px;
                    height: 100%;
                    overflow: hidden;
                }

                @media screen and (max-width: 1440px) {
                    right: -120px;
                }

                @include media-breakpoint-down(lg) {
                    right: 0;
                }

                &.left {
                    left: 0;

                    @media screen and (max-width: 1440px) {
                        left: -120px;
                    }

                    @include media-breakpoint-down(lg) {
                        left: 0;
                    }
                }

                @media (max-width: 1300px) {
                    max-width: 600px;
                }

                @include media-breakpoint-down(lg) {
                    aspect-ratio: 4/3;
                    border-radius: 10px;
                    left: 0;
                    margin: 0 auto;
                    overflow: hidden;
                }
            }

            .imageMobile {
                aspect-ratio: 4/3;
                height: 100%;
                width: 100%;
            }
        }

        .wrapperText {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            max-width: 528px;
            position: relative;
            width: 100%;
            z-index: 1;

            @media (max-width: 1300px) {
                max-width: 428px;
            }

            @include media-breakpoint-down(lg) {
                align-items: flex-start;
                min-width: 100%;
            }

            .wrapperBox {
                .title {
                    color: #000000;
                    font-family: 'DM Sans';
                    font-weight: 700;
                    margin-bottom: 10px;

                    @include media-breakpoint-down(lg) {
                        margin-bottom: 22px;
                    }

                    &.titleCenter {
                        text-align: center;
                    }

                    &.titleMedium {
                        font-size: 64px;
                        line-height: 65.73px;

                        @include media-breakpoint-down(lg) {
                            font-size: 32px;
                            line-height: 32.86px;
                        }
                    }

                    &.titleLarge {
                        font-size: 96px;
                        line-height: 98.59px;

                        @include media-breakpoint-down(lg) {
                            font-size: 40px;
                            line-height: 41px;
                        }
                    }

                    > p {
                        margin: 0;
                        margin-block-end: 0;
                        margin-block-start: 0;
                    }
                }

                .description {
                    color: #000000;
                    font-family: 'DM Sans';
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 19.53px;
                    max-width: 500px;

                    @media (max-width: 1300px) {
                        max-width: 400px;
                    }

                    @include media-breakpoint-down(lg) {
                        max-width: 100%;
                        padding: 0 9px;
                    }

                    > p {
                        margin: 0;
                        margin-block-end: 0;
                        margin-block-start: 0;
                    }
                }
            }
        }
    }
}
