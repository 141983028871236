@import 'styles/styles';

.container {
    display: flex;
    gap: 30px;
    height: 100%;
    margin: 90px 0 75px;
    position: relative;
    width: 100%;

    @media only screen and (min-width: 1300px) {
        margin-left: -87px;
        margin-left: -87px;
        width: calc(100% + 174px);
    }

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-bottom: 40px;
        margin-top: 42px;
    }
}

.content {
    height: 100%;
    padding: 0;
    width: 100%;

    .title {
        background: linear-gradient(90deg, #c4b9d9 0%, #bc1db5 48.44%, #ff0068 100%);
        -webkit-background-clip: text;
        background-clip: text;
        font-family: $title-font-family;
        font-size: 64px;
        font-weight: 400;
        line-height: 64px;
        margin-bottom: 17px;
        text-align: center;
        -webkit-text-fill-color: transparent;

        > p {
            margin: 0;
        }

        @include media-breakpoint-down(md) {
            font-size: 48px;
            line-height: 48px;
            margin-bottom: 7px;
        }
    }

    .description {
        color: #8b8f9a;
        font-family: 'Questrial';
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;

        @include media-breakpoint-down(md) {
            font-size: 24px;
            line-height: 32px;
        }

        > p {
            margin: 0;
        }
    }
}
