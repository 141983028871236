@import '@/styles/styles';

.container {
    background-color: #ffffff;
    box-shadow: 0px 12px 30px rgba(28, 22, 36, 0.1);
    color: $white;
    display: block;
    padding: 60px 40px;
    position: relative;
    text-decoration: none;
    width: 410px;
    @include withBorderRadius();

    @include media-breakpoint-down(lg) {
        padding: 32px 20px;
        width: 301px;
    }
}

.logo {
    height: 75px;
    margin-bottom: 40px;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include media-breakpoint-down(lg) {
        height: 60px;
        margin-bottom: 20px;
    }
}

.info {
    .name {
        color: #1c1624;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
    }

    .website {
        color: #65606c;
        font-size: 18px;
        line-height: 32px;
    }

    @include media-breakpoint-down(lg) {
        .name {
            font-size: 20px;
            line-height: 24px;
        }
        .website {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
