@import 'styles/styles';

.container {
    height: 100%;
    margin-bottom: 56px;
    margin-top: 66px;
    width: 100%;
    @include alight-item-center;

    @include media-breakpoint-down(md) {
        margin-bottom: 80px;
        margin-top: 40px;
    }

    // @include media-breakpoint-down(sm) {
    //     margin-left: calc(15px - (50vw - 50%));
    //     margin-right: calc(15px - (50vw - 50%));
    //     width: calc(100% + (50vw - 50%));
    // }

    @media screen and (max-width: 375px) {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        width: calc(100% + (50vw - 50%));
    }

    .button {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 150px;

        @include media-breakpoint-down(md) {
            margin-bottom: 60px;
        }
    }

    .content {
        display: grid;
        gap: 100px;
        grid-template-columns: repeat(3, 1fr);

        @include media-breakpoint-down(lg) {
            gap: 33px;
            grid-template-columns: repeat(1, 1fr);
        }

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
        }

        .item {
            .icon {
                height: 40px;
                margin: 0 auto;
                padding: 24px;
                width: 40px;
                position: relative;
                transform: translateZ(0);

                @include alight-item-center;
            }

            .title {
                font-family: $title-font-family;
                font-size: 24px;
                font-weight: 400;
                line-height: 32px;
                margin-bottom: 20px;
                text-align: center;
                color: #ffffff;

                @include media-breakpoint-down(md) {
                    margin-bottom: 31px;
                }
            }

            .description {
                font-family: $title-font-family;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #ffffff;
            }
        }
    }
}
