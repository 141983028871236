@import 'styles/styles';
@import '@/styles/variables';

.centeredBody {
    background: #7c39ff;
    border-radius: 30px;
    box-shadow: 0px 15px 30px rgba(124, 57, 255, 0.5);
    display: flex;
    padding: 32px;
    position: relative;
    width: auto;

    &-padding {
        &-none {
            padding: 0;

            @include media-breakpoint-down(lg) {
                padding: 0;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        width: 100%;
        // padding: 0;
    }
}
.container {
    height: 100%;
    width: 100%;
}

.title {
    &-font {
        &-default {
            color: #ffffff;
            font-family: 'Questrial';
            font-size: 48px;
            font-weight: 400;
            line-height: 56px;
            margin-bottom: 34px;
            max-width: 410px;

            @include media-breakpoint-down(lg) {
                font-size: 32px;
                line-height: 40px;
                max-width: 270px;
            }
        }

        &-small {
            color: #ffffff;
            font-family: 'Questrial';
            font-size: 64px;
            font-weight: 400;
            line-height: 64px;
            margin-bottom: 34px;
            max-width: 390px;

            @include media-breakpoint-down(lg) {
                font-size: 40px;
                line-height: 40px;
                max-width: 300px;
            }

            @include media-breakpoint-down(sm) {
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        font-size: 40px;
        line-height: 40px;
        text-align: center;
    }
}

.helpdeskInputs {
    margin-bottom: 44px;

    @include media-breakpoint-down(sm) {
        .be-form-group {
            grid-template-columns: 1fr 1fr !important;
        }
    }

    @include media-breakpoint-down(md) {
        .be-form-group {
            grid-template-columns: 1fr 1fr !important;
        }
    }
}

.margBottom {
    margin-bottom: 40px;

    input {
        border-bottom: 1px solid #8c52ff;
    }
    textarea {
        border-bottom: 1px solid #8c52ff;
    }
}

.button {
    margin-bottom: 32px;
    width: -moz-fit-content;
    width: fit-content;

    @include media-breakpoint-down(md) {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        text-align: center;
        width: 100%;
    }
}
