@import '@/styles/variables';
@import '@/styles/styles';

.container {
    margin-left: calc(50% - 50vw);
    padding-bottom: 100px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 147px;
    position: relative;
    width: 100vw;

    @include media-breakpoint-down(lg) {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 134px;
    }

    .wrapContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 auto;
        max-width: 1320px;
        width: 100%;
    }

    .wrapImg {
        border-bottom-left-radius: 30px !important;
        border-top-left-radius: 30px !important;
        height: auto;
        max-width: 100%;
        position: absolute;
        right: 0;
        top: 110px;
        width: 100vw;
        z-index: 1;

        @include media-breakpoint-down(lg) {
            max-width: 100%;
            position: absolute;
            right: -10px;
            top: 80px;
        }

        .img {
            border-bottom-left-radius: 30px !important;
            border-top-left-radius: 30px !important;
            box-shadow: 0px 24px 24px -8px rgba(181, 136, 105, 0.5);
            float: right;
            height: 590px;
            max-width: 42%;
            overflow: hidden;
            width: 100%;

            >div>span>img {
                border-bottom-left-radius: 30px !important;
                border-top-left-radius: 30px !important;
                overflow: hidden;
                width: 100%;
            }

            @include media-breakpoint-down(xl) {
                height: 690px;
                max-width: 50%;
            }

            @include media-breakpoint-down(lg) {
                height: 360px;
                max-width: 100%;
            }
        }
    }

    .gridContent {
        display: grid;
        gap: 40px;
        grid-template-columns: 70% auto;
        position: relative;

        @media screen and (max-width: 1320px) {
            grid-template-columns: 1fr 1fr;
        }

        @include media-breakpoint-down(xl) {
            gap: 20px;
            grid-template-columns: 1fr 1fr;
        }

        @include media-breakpoint-down(lg) {
            display: flex;
            flex-direction: column;
        }
    }

    .wrapContent {
        height: 100%;
        padding: 0 10px;
        position: relative;
        width: 100%;
        z-index: 2;


        h1 {
            margin-block-end: 0;
            margin-block-start: 0;
        }

        @include media-breakpoint-down(lg) {
            order: 2;
            padding: 0;
        }

        .heading {
            color: #1c1624;
            font-family: 'DM Sans';
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
        }

        .title {
            color: #1c1624;
            font-family: 'Questrial';
            font-size: 64px;
            font-weight: 400;
            line-height: 72px;
            margin: 18px 0 40px;
            max-width: 740px;

            @include media-breakpoint-down(lg) {
                font-size: 48px;
                line-height: 48px;
                margin-bottom: 26px;
            }

            @include media-breakpoint-down(sm) {
                max-width: 345px;
                letter-spacing: -1.44px;
            }

            >p {
                margin-block-end: 0;
                margin-block-start: 0;
            }
        }

        .description {
            color: #65606c;
            font-family: 'DM Sans';
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            max-width: 740px;

            >p {
                margin-block-end: 0;
                margin-block-start: 0;
            }
        }
    }

    .box {
        height: 100%;
        width: 100%;

        @include media-breakpoint-down(lg) {
            aspect-ratio: 16/9;
            height: 318px;
            order: 1;
        }
    }

    .background {
        height: 100%;
        left: 0;
        margin-left: calc(50% - 50vw);
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: 0;

        &Clipped {
            top: 50%;
        }
    }
}