@import 'styles/styles';

.root {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: $body-font-family;
    gap: 32px;
    position: relative;
    text-decoration: none;
    width: 100%;

    p {
        margin-block-end: 0;
        margin-block-start: 0;
    }

    .zoomedImageContainer {
        background-color: rgba($color: $dark-grey, $alpha: 0.1);
        overflow: hidden;
        @include withBorderRadius;
    }

    &:hover {
        .zoomedImage {
            transform: scale(1.1);
        }
    }
    .link {
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}
.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    @include linesLimit(2);
}

.description {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    @include linesLimit(3);
}

.btnLearnMore {
    margin: 20px 0;
}

.zoomedImageContainer {
    overflow: hidden;
    padding-top: 60.44%;
    position: relative;
    width: 100%;

    @include media-breakpoint-down(lg) {
        margin: 0 auto;
        max-height: 400px;
        max-width: 500px;
        padding-top: 40%;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 60.44%;
    }
}

.zoomedImage {
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateZ(0);
    transition: transform 300ms ease-in;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include media-breakpoint-down(lg) {
        text-align: center;
    }

    @include media-breakpoint-down(sm) {
        margin: 0;
        text-align: start;
    }
}
