@import 'styles/styles';

.container {
    height: 100%;
    width: 100%;

    .header {
        font-family: Questrial;
        font-size: 56px;
        font-weight: normal;
        line-height: 56px;

        @include media-breakpoint-down(md) {
            font-size: 48px;
            line-height: 48px;
        }
    }

    .wrapper {
        display: grid;
        gap: 60px;
        grid-template-columns: 1fr 1fr 1fr;
        margin: 60px 0;

        @include media-breakpoint-down(lg) {
            grid-template-columns: 1fr;
            margin: 30px 0;
        }
    }
}
