.container {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    row-gap: var(--row-gap, 48px);
    margin: 0 auto;
}

.row {
    display: flex;
    flex-direction: row;
    column-gap: var(--column-gap, 48px);
    row-gap: var(--row-gap);
    justify-content: center;
    align-items: center;
}

.wrap {
    flex-wrap: wrap;
}

.item {
    position: relative;
    width: auto;
    height: 100%;

    .image {
        position: relative;
    }

    img {
        max-height: 86px;
        height: 100%;
        width: auto;
        object-fit: contain;
    }
}

.hide {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

@media only screen and (max-width: 768px) {
    .row {
        row-gap: var(--row-gap, 36px);
        column-gap: 36px;
    }
}

@media only screen and (max-width: 520px) {
    .row {
        row-gap: var(--row-gap, 36px);
        column-gap: 26px;
        flex-wrap: wrap;
    }
}
