@import 'styles/styles';

.container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    height: 100%;
    margin: 75px 0 65px;
    position: relative;
    width: 100%;

    @include media-breakpoint-down(lg) {
        gap: 15px;
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;
        gap: 25px;
        margin: 40px 0 42px;
    }

    @include media-breakpoint-down(sm) {
        margin-left: calc(15px - (50vw - 50%));
        margin-right: calc(15px - (50vw - 50%));
        width: calc(100% - (30px - (100vw - 100%)));
    }

    .card {
        background: #f2ebff;
        border-radius: 30px;
        height: auto;
        padding: 41px 40px;
        position: relative;
        width: 100%;

        @include media-breakpoint-down(lg) {
            padding: 41px 20px;
        }

        @include media-breakpoint-down(md) {
            padding: 25px 25px;
        }
    }

    .title {
        color: $secondary;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        margin: 0 auto;

        > p {
            margin: 0;
        }

        @media (max-width: 1300px) {
            text-align: start;
        }

        @include media-breakpoint-down(lg) {
            font-size: 28px;
            line-height: 34px;
        }

        @include media-breakpoint-down(md) {
            text-align: start;
        }
    }

    .divider {
        background: $secondary;
        border-radius: 40px;
        height: 2px;
        margin: 25px 0 24px;
        width: 32px;

        @include media-breakpoint-down(md) {
            margin: 14px 0 14px;
        }
    }

    .description {
        font-family: $title-font-family;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;

        > p {
            margin: 0;
        }

        @include media-breakpoint-down(lg) {
            font-size: 16px;
        }
    }
}
