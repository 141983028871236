@import 'styles/styles';
@import '@/styles/variables';

.container {
    padding-top: 219px;
    position: relative;
    z-index: 1;
    padding-bottom: 220px;

    @media only screen and (min-width: 1300px) {
        margin-left: -4.5rem;
        margin-right: -4.5rem;
    }

    @media only screen and (max-width: 1200px) {
        padding-top: 100px;
    }

    @include media-breakpoint-down(lg) {
        padding-top: 124px;
        padding-bottom: 70px;
    }

    @media only screen and (max-width: 375px) {
        margin-left: -1rem;
        margin-right: -1rem;
    }

    .wrapper {
        position: relative;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 30px;
        width: 100%;
        height: 100%;

        @include media-breakpoint-down(xl) {
            grid-template-columns: 1fr 1fr;
        }

        @include media-breakpoint-down(lg) {
            grid-template-columns: 1fr;
            display: grid;
        }
    }

    .image {
        width: 100%;
        height: 605px;
        aspect-ratio: 4/3;

        @include media-breakpoint-down(md) {
            height: 100%;
            aspect-ratio: 1/1;
        }
    }

    .content {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 740px;
        // padding: 57.5px 0;

        h1 {
            margin-block-start: 0;
            margin-block-end: 0;
        }

        .title {
            font-family: 'Questrial';
            font-weight: 400;
            font-size: 64px;
            line-height: 64px;
            max-width: 573px;
            margin-top: 5px;

            @include media-breakpoint-down(lg) {
                font-size: 48px;
                line-height: 48px;
                max-width: 100%;
            }

            @include media-breakpoint-down(sm) {
                max-width: 345px;
            }
        }

        .subTitle {
            font-family: 'Questrial';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 40px;
            margin: 17px 0 30px;

            @include media-breakpoint-down(lg) {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .description {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #65606c;

            @include media-breakpoint-down(lg) {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }

    .btn {
        z-index: 1;
    }

    .wrapperForm {
        width: 100%;
        height: 100%;
    }
}

.background {
    background-color: #fff;
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 0;
}

.backgroundClippedBottom {
    bottom: -24px;
    top: unset;
}