@import 'styles/styles';
@import '@/styles/variables';

.container {
    width: 100%;
    height: 100%;
    margin-top: 50px;

    .box {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .bgShadowRight {
        width: 18%;
        position: absolute;
        right: -10%;
        height: 100%;
        background: linear-gradient(270deg, #1c1624 62.45%, rgba(28, 22, 36, 0) 100%);
        z-index: 2;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .bgShadowLeft {
        width: 7.5%;
        position: absolute;
        left: -5%;
        height: 100%;
        background: linear-gradient(90deg, #1c1624 62.45%, rgba(28, 22, 36, 0) 100%);
        z-index: 2;

        @include media-breakpoint-down(md) {
            display: none !important;
        }
    }

    :global {
        .swiper {
            overflow: visible;

            .swiper-wrapper {
                padding-bottom: 89px;
            }

            .swiper-container {
                overflow: visible;
            }

            .swiper-slide {
                // font-size: 18px;
            }

            .swiper-slide {
                // opacity: 1.25;
            }

            .swiper-slide-visible {
                // opacity: 1;
            }

            .swiper-slide {
                width: auto;
            }
        }

        .autoplay:active {
            transform: scale(0.8);
        }

        .swiper-button-prev {
            top: 40%;
            left: -30px;
            background: linear-gradient(45deg, #7c39ff 0%, #bc1db5 49.12%, #ff0068 100%);
            height: 64px;
            width: 64px;
            padding: 16px;
            border-radius: 88px;

            > svg {
                transform: rotate(180deg);
            }

            @media screen and (max-width: 1400px) {
                left: -15px;
            }

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        .swiper-button-prev::after {
            font-size: 0;
            content: '';
            align-items: center;
            display: flex;
            justify-self: center;
            background-position: top;
        }

        .swiper-button-next {
            top: 38%;
            z-index: 9999999;
            right: -20px;
            background: linear-gradient(45deg, #7c39ff 0%, #bc1db5 49.12%, #ff0068 100%);
            height: 63px;
            width: 63px;
            border-radius: 88px;
            position: absolute;

            @media screen and (max-width: 1400px) {
                right: -15px;
            }

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        .swiper-button-next::after {
            font-size: 0;
            background-position: center;
        }

        .swiper-button-disabled {
            display: none;
        }

        .swiper-pagination {
            bottom: 10px;
            left: 5%;
            position: absolute;
            width: 100%;

            @include media-breakpoint-down(sm) {
                left: 5%;
            }

            .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
                margin-right: 30px;
                opacity: 0.5;
                background-color: #2e243d;

                @include media-breakpoint-down(sm) {
                    margin-right: 36px;
                }

                &.swiper-pagination-bullet-active {
                    opacity: 1;
                    background: linear-gradient(45deg, #7c39ff 0%, #bc1db5 49.12%, #ff0068 100%);
                }
            }
        }
    }
}
