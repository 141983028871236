@import 'styles/styles';
@import '@/styles/variables';

.container {
    position: relative;
    z-index: 0;
    padding-bottom: 140px;

    @media only screen and (min-width: 1300px) {
        margin-left: -87px;
        margin-left: -87px;
        width: calc(100% + 174px);
    }

    .children {
        z-index: 21;
        position: relative;
        margin-bottom: 200px;
        background-color: fff;
    }

    @include media-breakpoint-down(md) {
        .children {
            margin-bottom: 0;
        }
    }
}

.background {
    background-color: $primary;
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 0;
}

.backgroundClippedBottom {
    bottom: 0;
    top: unset;
    z-index: 1;
}
