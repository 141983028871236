@import '@/styles/variables';
@import '@/styles/styles';

.container {
  position: relative;
  margin-bottom: 35px;

  @media only screen and (min-width: 1300px) {
    margin-left: -4.5rem;
    margin-right: -4.5rem;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 32px;
    padding-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: calc(15px - (50vw - 50%));
    margin-right: calc(15px - (50vw - 50%));
    width: calc(100% - (30px - (100vw - 100%)));
  }

  .wrapperBox {
    display: grid;
    grid-template-columns: 520px 1fr;
    gap: 20px;

    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h1,
    h2,
    h3 {
      padding: 0;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    @include media-breakpoint-down(xl) {
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
      gap: 22px;
    }
  }

  .boxLeft {
    position: relative;


    .wrapperTag {
      background-color: #F3F3F5;
      border-radius: 100px;
      padding: 4px 16px;
      display: flex;
      align-content: center;
      justify-content: center;
      max-width: fit-content;
      margin-bottom: 20px;

      @include media-breakpoint-down(lg) {
        margin-bottom: 10px;
      }

      .tag {
        font-family: "DM Sans";
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #1C1624;
      }
    }

    .title {
      font-family: "Questrial";
      font-size: 48px;
      font-weight: 400;
      line-height: 56px;
      margin-bottom: 20px;
      max-width: 420px;

      @include media-breakpoint-down(lg) {
        font-size: 40px;
        line-height: 48px;
      }
    }

    .description {
      font-family: "DM Sans";
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 20px;

      @include media-breakpoint-down(lg) {
        margin-bottom: 12px;
      }
    }

    .subDescription {
      font-family: "DM Sans";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .boxRight {
    position: relative;

    .boxOne {
      padding: 32px;
      position: relative;
      background-color: violet;
    }

    .wrapperItem {
      background: rgb(255, 229, 240);
      position: relative;
      padding: 32px;
      border-radius: 24px;
      cursor: pointer;
      margin-bottom: 16px;

      @include media-breakpoint-down(lg) {
        padding: 24px;
        margin-bottom: 8px;
      }

      .wrapperHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        .headerLeft {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
        }

        .icon {
          width: 40px;
          height: 40px;
          background-color: rgba(255, 0, 104, 1);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          flex-shrink: 0;

          .image {
            width: 100%;
            height: 100%;
            max-width: 21px;
            max-height: 21px;

            >span>img {
              width: 100%;
              height: 100%;
              object-fit: contain !important;
            }
          }
        }

        .text {
          font-family: "DM Sans";
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;

          @include media-breakpoint-down(sm) {
            max-width: 218px;
          }
        }
      }

      .wrapperDescription {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s all;
        display: none;

        .description {
          max-width: 676px;

          @include media-breakpoint-down(lg) {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 20px;
            max-width: 100%;
          }
        }
      }

      &.expanded .wrapperDescription {
        display: block;
        max-height: 1000px;
        transition: max-height 0.5s all;
        transform: translateY(10px);

        @include media-breakpoint-down(lg) {
          transform: translateY(20px);
        }
      }

      .arrow {
        transition: transform 0.5s;

      }

      &.expanded .arrow {
        transition: transform 0.5s all;
        transform: translateY(-2.5px) rotate(180deg);
      }
    }

    .button {
      display: none;

      @include media-breakpoint-down(lg) {
        display: flex;
        margin: 24px auto 0px;
        align-items: center;
        justify-content: center;
        padding: 16px 24px;
        font-size: 24px;
        box-shadow: 0px 5px 10px 0px rgba(255, 0, 104, 0.48);
        background-color: #FF0068 !important;
        background: #FF0068;
      }

      @include media-breakpoint-down(sm) {
        display: flex;
        margin: 24px auto 0px;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 16px 24px;
        font-size: 24px;
        box-shadow: 0px 5px 10px 0px rgba(255, 0, 104, 0.48);
        background-color: #FF0068 !important;
        background: #FF0068;
      }
    }
  }
}