@import 'styles/styles';
.root {
    padding-top: 60px;
    @include media-breakpoint-down(lg) {
        padding-top: 32px;
    }
    &.noOverflow .container {
        padding-bottom: 60px;
        .image {
            position: relative;
        }
        .textItem {
            width: 49%;
            flex-shrink: 0;
        }
        .imageItem {
            width: auto;
            flex-grow: 1;
        }
        .textBlock {
            padding-bottom: 0;
        }
    }
    &.left .container {
        .textBlock {
            flex-direction: row-reverse;
        }
    }
    &.fluid {
        .container {
            padding-bottom: 120px;
            @include media-breakpoint-down(lg) {
                padding-bottom: 16px;
            }
            .textBlock {
                flex-direction: column-reverse;
                gap: 80px;
                padding-top: 0;
                padding-bottom: 0;
                @include media-breakpoint-down(lg) {
                    gap: 64px;
                    padding-bottom: 16px;
                }
            }
            .imageItem {
                position: static;
                width: 100%;
            }
            .image {
                position: static;
                position: relative;
            }
            .textItem {
                width: 100%;
                max-width: 946px;
                margin-left: auto;
                //padding-left: 190px;
                @include media-breakpoint-down(lg) {
                    padding-left: 0;
                    max-width: unset;
                }
            }
            .contentContainer {
                position: relative;
            }
            //.content {
            //  //padding-left: 190px;
            //  @include media-breakpoint-down(lg) {
            //    padding-left: 0;
            //  }
            //}
        }
    }
}
.container {
    position: relative;
    padding-bottom: 20px;
}

.fullWidth {
    width: 100vw;
    margin-left: calc(50% - 50vw);
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    //padding-bottom: 120px;
}

.textItem {
    width: 50%;
}

.imageItem {
    position: relative;
    width: 50%;
}

.contentContainer {
    margin: 0 auto;
    @include layout();
    //padding-bottom: 120px;
}
.image {
    position: absolute;
    bottom: 0;
    max-width: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include withBorderRadius();
    overflow: hidden;
}

.textBlock {
    padding-bottom: 40px;
    display: flex;
    gap: 121px;
    //justify-content: space-between;
    //align-items: flex-start;
}
