@import '@/styles/variables';
@import '@/styles/styles';

.container {
    height: 100%;
    position: relative;
    width: 100%;
}

.container:hover {
    .card {
        box-shadow: 0 15px 30px rgba(28, 22, 36, 0.35);
    }
}

.card {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);

    height: 100%;
    padding: 48px 48px;
    position: relative;
    transition: box-shadow 0.3s ease-in-out;

    @media screen and (max-width: 1440px) {
        height: 100%;
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        padding: 32px 24px;
    }
}

.wrapLogo {
    height: 80px;
    max-width: 80%;
    position: relative;
    width: 100%;
    .box {
        height: 100%;
        left: 0;
        max-height: 80px;
        position: absolute;
        top: 0;
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        height: fit-content;
        .box {
            position: relative;
        }
    }
}

.title {
    color: #1c1624;
    font-family: 'Questrial';
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    margin-top: 28px;

    @include media-breakpoint-down(md) {
        font-size: 24px;
        line-height: 32px;
        margin-top: 25px;
    }
}

.description {
    color: #1c1624;
    font-family: 'DM Sans';
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 14px;
}
