@import '@/styles/styles';

.btn {
    align-items: center;
    border: 2px solid #000000;
    border-radius: 100px;
    color: #000;
    cursor: pointer;
    display: inline-flex;
    font-family: $title-font-family;
    font-size: 16px;
    position: relative;
    font-weight: 700;
    width: 100%;
    gap: 8px;
    line-height: 20px;
    background-clip: padding-box;
    border: 10px solid transparent;
    transition: background-color 0.3s ease, box-shadow 0.2s, transform 0.2s, opacity 0.2s;

    padding: 6px 25px;

    > svg {
        flex-shrink: 1;
    }

    &.shadowNone {
        box-shadow: none;
    }

    &:disabled {
        cursor: inherit;
        opacity: 0.8;
    }

    &-secondary {
        background: $mirage;
        box-shadow: 0px 5px 10px rgba($color: $mirage, $alpha: 0.8);
    }

    &-highty {
        align-items: center;
        background: $text-color-primary-dark linear-gradient(90deg, $secondary 0%, $barney 48.96%, $primary 100%);
        border: none;
        border-radius: 100px;
        box-shadow: 0px 5px 10px rgba($color: $purplish-blue, $alpha: 0.5);
        color: $white;
        cursor: pointer;
        display: inline-flex;
        font-family: $title-font-family;
        font-size: 16px;
        font-weight: 700;
        gap: 8px;
        line-height: 20px;
        padding: 6px 25px;
        transition: background-color 0.3s ease, box-shadow 0.2s, transform 0.2s, opacity 0.2s;
    }

    &-default {
        height: 64px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        width: 244px;
    }

    &-small {
        height: 64px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        width: auto;
    }

    &-medium {
        height: 32px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        width: 244px;
    }

    &:hover {
        background-color: lighten($text-color-primary-dark, 10%);
        transform: translateY(-4px);
    }

    &-color {
        &-black {
            color: #000000;
        }

        &-white {
            color: #ffffff;
        }

        &-purple {
            color: #7c39ff;
        }
    }

    &-theme {
        &-black {
            background-color: #000000;
        }

        &-transparent {
            background-color: transparent;
        }

        &-purple {
            background-color: #7c39ff;
        }
    }

    &-border {
        &-transparent {
            border: none;
        }

        &-color {
            border: 2px solid rgb(169, 38, 203);
        }
    }
}