@import '@/styles/variables';
@import '@/styles/styles';

.container {
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 155px;
}

.textContent {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;

    @include media-breakpoint-down(md) {
        text-align: start;
    }

    .title {
        font-family: 'Questrial';
        font-weight: 400;
        font-size: 64px;
        line-height: 64px;
        color: #1c1624;

        > p {
            margin-block-start: 0;
            margin-block-end: 0;
        }

        @include media-breakpoint-down(md) {
            font-size: 48px;
            line-height: 48px;
        }
    }

    .description {
        font-family: 'DM Sans';
        font-weight: 400;
        font-size: 24px;
        color: #1c1624;
        line-height: 32px;
        margin-top: -8px;

        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 26px;
        }
    }
}

.cardContent {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-top: 120px;

    @media screen and (max-width: 1495px) {
        gap: auto;
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        margin-top: 40px;
    }
}

.cards {
    width: 100%;
    height: 100%;
    position: relative;
}

.background {
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 0;

    &Clipped {
        top: 50%;
    }
}
