@import '@/styles/styles';

.container {
    background-color: #2e243d;
    display: block;
    height: 730px;
    width: 500px;
    padding: 32px;
    text-decoration: none;
    display: block;
    position: relative;
    @include withBorderRadius();

    @include media-breakpoint-down(lg) {
        padding: 32px 20px;
        width: 345px;
    }

    @include media-breakpoint-down(sm) {
        padding: 32px 20px;
        height: 680px;
        width: 325px;
    }
}

.title {
    color: #ffffff;
    font-family: 'Questrial';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;

    @include media-breakpoint-down(lg) {
        font-size: 32px;
        line-height: 32px;
    }

    @include media-breakpoint-down(md) {
    }
}

.divider {
    background-color: #ffffff;
    height: 2px;
    margin: 16px 0;
    opacity: 20%;
    width: 32px;
}

.description {
    color: #ffffff;
    font-family: 'DM Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 16px;

    @include media-breakpoint-down(lg) {
        font-size: 18px;
        line-height: 24px;
    }
}

.imageContainer {
    height: 300px;
    width: 100%;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-down(lg) {
        height: 200px;
    }
}
