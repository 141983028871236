$blue: #22d7ee;
$dark-blue: #06b6d4;
$darkest-blue: #0e7490;
$dark-coal: #0f172a;
$dark-grey: #cbd5e1;
$light-grey: #e2e8f0;
$darkest-grey: #475569;
$white: #ffffff;
$mirage: #1c1624;
$mid-grey: #65606c;
$rose: #ff0068;
$aztec-purple: #7c39ff;
$purplish-blue: #6619ff;
$purplish-blue-darken: #6333C6;
$barney: #bc1db5;
$color-cyan: #00d7e1;

$primary: $rose;
$secondary: $aztec-purple;
$text-color-primary-dark: $mirage;
$text-color-secondary-dark: $mid-grey;
$text-color-primary-light: $white;
$text-color-secondary-light: $white;

$body-font-family: 'DM Sans', Manrope -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$title-font-family: Questrial;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 769px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$base-border-radius: 30px;
$base-border-radius-mobile: 15px;
